import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"

import Seo from "../../components/seo"
import Breadcrumb from "../../components/Breadcrumb"
import { CategoryMain } from "../../styles/Layout"
import {
  FeaturedImage,
  HeroContent,
} from "../../components/Category/CategoryElements"
import {
  LegalWrapper,
  LegalContent,
  LegalMeta,
} from "../../components/Legal/LegalElements"
import Newsletter from "../../components/Newsletter"

const PrivacyPolicy = ({ data }) => {
  const {
    slug,
    title,
    seoDesc,
    heading,
    description,
    updatedAt,
    featuredImage,
    body,
  } = data.legal

  const image = getImage(featuredImage)
  const featImgSrc = getSrc(featuredImage)

  return (
    <>
      <Seo
        title={title}
        description={seoDesc}
        image={featImgSrc}
        imageAlt={featuredImage?.description || title}
        article={false}
      />

      <CategoryMain id="main-content">
        <FeaturedImage>
          <GatsbyImage
            image={image}
            alt={title}
            style={{
              height: "59vh",
              gridColumn: "1/-1",
              gridRow: "1/-1",
            }}
          />
          <Breadcrumb categoryTitle={title} categoryLink={`legal/${slug}/`} />

          <HeroContent>
            <h1>{heading}</h1>
            <p>{description}</p>
          </HeroContent>
        </FeaturedImage>

        <LegalWrapper>
          <LegalMeta>
            Last updated on {updatedAt} | {body.childMarkdownRemark.timeToRead}{" "}
            minutes read
          </LegalMeta>

          <LegalContent
            dangerouslySetInnerHTML={{
              __html: body.childMarkdownRemark.html,
            }}
          />
        </LegalWrapper>
        <Newsletter />
      </CategoryMain>
    </>
  )
}

export default PrivacyPolicy

export const legalQuery = graphql`
  query ($id: String!) {
    legal: contentfulLegal(id: { eq: $id }) {
      id
      slug
      title
      seoDesc: seoDescription
      heading
      description
      updatedAt(formatString: "LL")

      featuredImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          aspectRatio: 1.777777
          quality: 65
        )
      }

      body {
        childMarkdownRemark {
          timeToRead
          html
        }
      }
    }
  }
`
