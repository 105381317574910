import styled from "@emotion/styled"
import { StyledPostContent } from "../Article/ArticleElements"

export const LegalWrapper = styled.article`
  grid-column: center-start / center-end;
`

export const LegalContent = styled(StyledPostContent)`
  margin-inline: auto;

  hr {
    border: none;
    border-top: 3px double var(--grey-6);
    color: var(--grey-6);
    height: 5px;
    margin-bottom: 4rem;
    margin-top: 1rem;
    overflow: visible;
    text-align: center;
    max-width: 64rem;

    &::after {
      background: var(--white);
      content: "§";
      padding: 0 4px;
      position: relative;
      top: -12px;
    }
  }
`

export const LegalMeta = styled.p`
  color: var(--grey-7);
  /* margin: 0 auto; */
  margin-block: 2rem 4rem;
  /* max-width: min(68.6rem, 100%); */

  /* max-width: 78ch; */
`
